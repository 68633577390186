import { Box, Typography, IconButton, CircularProgress } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useState } from "react";

import { useAuthContext } from '../hooks/useAuthContext'

export default function TicketType(props) {
    // const { user } = useAuthContext();
    const { name, section, price, currency, itemId, available, startDate, endDate, visible, remainingQuantity } = props.ticketType;
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);
  
    const handleIncrease = () => {
        props.handleTicket(itemId, 1, price);
        setValue(value + 1);
    };
  
    const handleDecrease = () => {
        props.handleTicket(itemId, -1, price);
        if (value > 0) setValue(value-1);
    };

    const parseDate = (date) => {
        if (date) {
            date = new Date(date);
            const options = { month: "long", day: "numeric" };
            const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
            return formattedDate
        } else {
            return null
        }
    }



    return (
        <>
            {visible &&
            <Box
            sx={{display: "flex", color: "white", justifyContent: "space-between", width: "100%", paddingLeft: '15px', paddingRight: '15px', paddingTop: '25px', paddingBottom: '25px', 
            borderStyle: 'solid', borderColor: "#1a1c1f", borderRadius: '20px'}}
            >
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", gap: "10px"}}>
                    {loading && <CircularProgress size="15px"/>}
                    <Box
                    sx={{display: "flex", flexDirection: "column", gap: "2px"}}>
                        <Typography fontSize="18px" fontWeight="600">
                            {section} - {name}
                        </Typography>
                        <Box
                        sx={{display: "flex", gap: "5px"}}>
                            <Typography fontSize="12px">
                                {price} {currency}
                            </Typography>
                            {startDate && endDate &&
                            <>
                            <Typography fontSize="12px">
                            •
                            </Typography>
                            <Typography fontSize="12px">
                                {parseDate(startDate)} - {parseDate(endDate)}
                            </Typography>
                            </>
                            }
                        </Box>
                        {remainingQuantity &&
                            <Typography fontSize="15px" fontWeight="600">
                                Only {remainingQuantity} tickets left!
                            </Typography>
                        }
                    </Box>
                </Box>
                {visible &&
                <Box
                sx={{display: "flex", gap: "3px"}}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        columnGap: "30px",
                        alignItems: "center",
                        width: "100%"
                    }}>
                        { loading &&
                        <IconButton disabled variant="outlined" onClick={() => handleDecrease()} fontSize="small"
                        style={{
                            color: "white",
                            padding: "0px"
                        }}
                        >
                            <Remove fontSize="small"/>
                        </IconButton>
                        }
                        { !loading &&
                        <IconButton variant="outlined" onClick={() => handleDecrease()} fontSize="small"
                        style={{
                            color: "white",
                            padding: "0px"
                        }}
                        >
                            <Remove fontSize="small"/>
                        </IconButton>
                        }
                        <Typography fontWeight="700" fontSize="20px" color="white">{value}</Typography>
                        { loading &&
                        <IconButton disabled variant="outlined" onClick={() => handleIncrease()}  fontSize="small"
                        style={{
                            color: "white",
                            padding: "0px"
                        }}
                        >
                            <Add fontSize="small"/>
                        </IconButton>
                        }
                        { !loading &&
                        <IconButton variant="outlined" onClick={() => handleIncrease()}  fontSize="small"
                        style={{
                            color: "white",
                            padding: "0px"
                        }}
                        >
                            <Add fontSize="small"/>
                        </IconButton>
                        }
                    </Box>
                </Box>}
            </Box>}
            {!visible && new Date(startDate) > new Date() &&
            <Box
            sx={{display: "flex", color: "white", justifyContent: "space-between", width: "100%", paddingLeft: '15px', paddingRight: '15px', paddingTop: '25px', paddingBottom: '25px', 
            borderStyle: 'solid', borderColor: "#1a1c1f", borderRadius: '20px'}}
            >
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", gap: "10px"}}>
                    {loading && <CircularProgress size="15px"/>}
                    <Box
                    sx={{display: "flex", flexDirection: "column", gap: "0px"}}>
                        <Typography fontSize="18px" fontWeight="600">
                            {section} - {name}
                        </Typography>
                        <Box
                        sx={{display: "flex", gap: "5px"}}>
                            <Typography fontSize="12px">
                                {price} {currency}
                            </Typography>
                            <Typography fontSize="12px">
                            •
                            </Typography>
                            <Typography fontSize="12px">
                                {parseDate(startDate)} - {parseDate(endDate)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {visible &&
                <Box
                sx={{display: "flex", gap: "3px"}}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        columnGap: "30px",
                        alignItems: "center",
                        width: "100%"
                    }}>
                        { loading &&
                        <IconButton disabled variant="outlined" onClick={() => handleDecrease()} fontSize="small"
                        style={{
                            color: "white",
                            padding: "0px"
                        }}
                        >
                            <Remove fontSize="small"/>
                        </IconButton>
                        }
                        { !loading &&
                        <IconButton variant="outlined" onClick={() => handleDecrease()} fontSize="small"
                        style={{
                            color: "white",
                            padding: "0px"
                        }}
                        >
                            <Remove fontSize="small"/>
                        </IconButton>
                        }
                        <Typography fontWeight="700" fontSize="20px" color="white">{value}</Typography>
                        { loading &&
                        <IconButton disabled variant="outlined" onClick={() => handleIncrease()}  fontSize="small"
                        style={{
                            color: "white",
                            padding: "0px"
                        }}
                        >
                            <Add fontSize="small"/>
                        </IconButton>
                        }
                        { !loading &&
                        <IconButton variant="outlined" onClick={() => handleIncrease()}  fontSize="small"
                        style={{
                            color: "white",
                            padding: "0px"
                        }}
                        >
                            <Add fontSize="small"/>
                        </IconButton>
                        }
                    </Box>
                </Box>}
            </Box>}
            {new Date(endDate) < new Date() &&
            <Box
            sx={{display: "flex", color: "white", justifyContent: "space-between", width: "100%", paddingLeft: '15px', paddingRight: '15px', paddingTop: '25px', paddingBottom: '25px', 
            borderStyle: 'solid', borderColor: "#1a1c1f", borderRadius: '20px'}}
            >
                <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", gap: "10px"}}>
                    <Box
                    sx={{display: "flex", flexDirection: "column", gap: "0px", color: "#9CA3AF"}}>
                        <Typography fontSize="18px" fontWeight="600">
                            {section} - {name}<br/>SOLD OUT
                        </Typography>
                        <Box
                        sx={{display: "flex", gap: "5px"}}>
                            <Typography fontSize="12px">
                                {price} RON
                            </Typography>
                            <Typography fontSize="12px">
                            •
                            </Typography>
                            <Typography fontSize="12px">
                                {parseDate(startDate)} - {parseDate(endDate)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>}
        </>
    )
   
}