import { Container, CssBaseline, Box, Typography, Divider, Button, Radio, ButtonGroup, Slide, CircularProgress, TextField, Select, MenuItem,
Checkbox,
Alert,
Collapse,
IconButton, ImageList} from "@mui/material"
import { LocationOn as Location, MusicNote as Music, Headphones, FaceRetouchingOff as Minor, 
     Checkroom as Clothing} from "@mui/icons-material"
import TicketType from "../components/TicketType"
import { useState } from "react"
import { useAuthContext } from "../hooks/useAuthContext"
import { Link } from "react-router-dom"
import Navbar from "../components/Navbar"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { ConfirmationNumber as TicketIcon, CheckCircle, Phone, TableBar } from "@mui/icons-material"
import Footer from "../components/Footer"

import { useSearchParams } from "react-router-dom";



export default function Party() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const eventId = searchParams.get('eventId');

    const [sharingTableSubmitted, setSharingTableSubmitted] = useState(false);
    const [waitListSubmitted, setWaitListSubmitted] = useState(false);

    const [showCart, setShowCart] = useState(true);
    const [total, setTotal] = useState(0);

    const { user } = useAuthContext();

    const [items, setItems] = useState({});
    // const eventId = "67165d7a51e7fdea93f086a8";


    const handleTicket = (itemId, quantity, price) => {
        if (quantity > 0) {
            if (itemId in items) {
                items[itemId].quantity += 1;
            } else {
                items[itemId] = {itemId, quantity: 1}
            }
            setTotal(total + price)
        } else if (quantity < 0) {
            if (itemId in items) {
                if (items[itemId].quantity > 1) {
                    items[itemId].quantity -= 1
                    setTotal(total - price)
                } else if(items[itemId].quantity == 1) {
                    delete items[itemId];
                    setTotal(total - price)
                }
            }
        }
    }

    const [tableSelected, setTableSelected] = useState(null);
    const [deposit, setDeposit] = useState(0);
    const [tablePrice, setTablePrice] = useState(0);
    const [notes, setNotes] = useState('');
    const [people, setPeople] = useState(null);

    const handleSelectDeposit = (deposit, price) => {
        // setTotal(total - tablePrice);
        setDeposit(deposit);
        setTablePrice(price * deposit / 100);
        // setTotal(total + tablePrice);
    }

    const [ticketIncluded, setTicketIncluded] = useState(null);
    const [tableQuantity, setTableQuantity] = useState(0);
    const handleTableSelected = (itemId, includesTickets=false, capacity=0, minPeople) => {
        if (!includesTickets) {
            setTicketIncluded(null);
            setTableSelected(itemId);
            setDeposit(0);
            setTablePrice(0);
        } else {
            setTableSelected(itemId);
            let minPrice = tickets[0].price
            let minTicket = tickets[0]
            for (let t of tickets) {
                if (t.price < minPrice) {
                    minPrice = t.price
                    minTicket = t
                }
            }
            setTicketIncluded(minTicket);
            setTablePrice(minPrice * capacity);
        }
        setWaitList(null);
        setName("");
        setItemPhone("");
        setNotes("");
        setPeople(minPeople);
        setSharingTable(false)
        setTableQuantity(1);
    }

    const [waitList, setWaitList] = useState(null);
    const [name, setName] = useState("");
    const [itemPhone, setItemPhone] = useState("");

    // const handleSubmitWaitList = async () => {
    //     if (!waitList) {
    //         setErrorMessage("You have not selected waitlist");
    //         setShowError(true);
    //         setTimeout(() => {
    //             setShowError(false);
    //         }, 2500);
    //         setTimeout(() => {
    //             setErrorMessage(null);
    //         }, 2700);
    //     } else if (people == 0 || people == null) {
    //         setErrorMessage("Please enter the number of people for your reservation");
    //         setShowError(true);
    //         setTimeout(() => {
    //             setShowError(false);
    //         }, 2500);
    //         setTimeout(() => {
    //             setErrorMessage(null);
    //         }, 2700);
    //     } else if (!name) {
    //         setErrorMessage("Please enter your name to join the waitlist");
    //         setShowError(true);
    //         setTimeout(() => {
    //             setShowError(false);
    //         }, 2500);
    //         setTimeout(() => {
    //             setErrorMessage(null);
    //         }, 2700);
    //     } else if (!itemPhone) {
    //         setErrorMessage("Please enter your phone number to join the waitlist")
    //         setShowError(true);
    //         setTimeout(() => {
    //             setShowError(false);
    //         }, 2500);
    //         setTimeout(() => {
    //             setErrorMessage(null);
    //         }, 2700);
    //     } else {
    //         const response = await fetch('https://partyapp-404300.lm.r.appspot.com/api/event/waitlist', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({itemId: waitList, name: name, people, phone: itemPhone, eventId})
    //         })
    //         // const response = await fetch('/api/event/waitlist', {
    //         //     method: 'POST',
    //         //     headers: {
    //         //         'Content-Type': 'application/json',
    //         //     },
    //         //     body: JSON.stringify({itemId: waitList, name: name, people, phone: itemPhone, eventId})
    //         // })
    //         const json = await response.json();
    //         if (response.ok) {
    //             setSuccessMessage(json.message);
    //             setShowSuccess(true);
    //             setWaitListSubmitted(true);
    //             setTimeout(() => {
    //                 setShowSuccess(false);
    //             }, 2500);
    //             setTimeout(() => {
    //                 setSuccessMessage(null);
    //             }, 2700);
    //         } else {
    //             setErrorMessage(json.message);
    //             setShowError(true);
    //             setTimeout(() => {
    //                 setShowError(false);
    //             }, 2500);
    //             setTimeout(() => {
    //                 setErrorMessage(null);
    //             }, 2700);
    //         }
    //     }
    // }

    const handleSubmitSharingTable = async () => {
        if (!sharingTable) {
            setErrorMessage("You have not selected sharing table");
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 2500);
            setTimeout(() => {
                setErrorMessage(null);
            }, 2700);
        } else if (people == 0 || people == null) {
            setErrorMessage("Please enter the number of people for your reservation");
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 2500);
            setTimeout(() => {
                setErrorMessage(null);
            }, 2700);
        } else if (!name) {
            setErrorMessage("Please enter your name to create a sharing table");
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 2500);
            setTimeout(() => {
                setErrorMessage(null);
            }, 2700);
        } else if (!itemPhone) {
            setErrorMessage("Please enter your phone number to create a sharing tab;e")
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 2500);
            setTimeout(() => {
                setErrorMessage(null);
            }, 2700);
        } else {
            const response = await fetch('https://partyapp-testing-443802.ew.r.appspot.com/api/event/sharing', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({name: name, people, phone: itemPhone, eventId})
            })
            // const response = await fetch('/api/event/sharing', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({name: name, people, phone: itemPhone, eventId})
            // })
            const json = await response.json();
            if (response.ok) {
                setSuccessMessage(json.message);
                setShowSuccess(true);
                setSharingTableSubmitted(true);
                setTimeout(() => {
                    setShowSuccess(false);
                }, 2500);
                setTimeout(() => {
                    setSuccessMessage(null);
                }, 2700);
            } else {
                setErrorMessage(json.message);
                setShowError(true);
                setTimeout(() => {
                    setShowError(false);
                }, 2500);
                setTimeout(() => {
                    setErrorMessage(null);
                }, 2700);
            }
        }
    }

    const handleWaitList = (itemId) => {
        setWaitList(itemId);
        setNotes("");
        setTableSelected(null);
        setTicketIncluded(null);
        setDeposit(0);
        setTablePrice(0);
        setPeople(null);
        setSharingTable(false)
        setItemPhone("")
        setName("")
    }
    
    const [sharingTable, setSharingTable] = useState(false);
    const handleSharingTable = () => {
        setWaitList(null);
        setNotes("");
        setTableSelected(null);
        setTicketIncluded(null);
        setDeposit(0);
        setTablePrice(0);
        setPeople(2);
        setItemPhone("")
        setSharingTable(true);
        setName("")
    }
    

    const [extraServicesCost, setExtraServicesCost] = useState(0);
    const [concierge, setConcierge] = useState(null);
    const [conciergeNotes, setConciergeNotes] = useState("")

    const [specialist, setSpecialist] = useState(null);
    const [driver, setDriver] = useState(null);
    const [driverType, setDriverType] = useState(null);
    const [security, setSecurity] = useState(null);
    const [accessory, setAccessory] = useState(null);
    const handleExtraService = (itemId, category, price) => {
        if (category == "concierge") {
            if (!concierge) {
                setConcierge(itemId);
                setExtraServicesCost(extraServicesCost + price);
            } else {
                setConcierge(null);
                setExtraServicesCost(extraServicesCost - price);
            }
        } else if (category == "specialist") {
            if (!specialist) {
                setSpecialist(itemId);
                console.log("here")
                setExtraServicesCost(extraServicesCost + price);
            } else {
                setSpecialist(null);
                setExtraServicesCost(extraServicesCost - price);
            }
        } else if (category == "driver") {
            if (!driver) {
                setDriver(itemId);
                setExtraServicesCost(extraServicesCost + price);
            } else {
                setDriver(null);
                setExtraServicesCost(extraServicesCost - price);
            }
        } else if (category == "security") {
            if (!security) {
                setSecurity(itemId);
                setExtraServicesCost(extraServicesCost + price);
            } else {
                setSecurity(null);
                setExtraServicesCost(extraServicesCost - price);
            }
        } else if (category == "accessory") {
            if(!accessory) {
                setAccessory(itemId);
                setExtraServicesCost(extraServicesCost + price);
            } else {
                setAccessory(null);
                setExtraServicesCost(extraServicesCost - price);
            }
        }
    }
    const [loadingButton, setLoadingButton] = useState(false);
    const completePurchase = async () => {
        if (tableSelected != null && tableSelected != "") {
            if (people == null || people == 0) {
                setErrorMessage("Please enter the number of people for your reservation");
                setShowError(true);
                setTimeout(() => {
                    setShowError(false);
                }, 2500);
                setTimeout(() => {
                    setErrorMessage(null);
                }, 2700);
                return;
            }
            if (!ticketIncluded) {
                items[tableSelected] = {itemId: tableSelected, deposit, notes, people, quantity: tableQuantity};
            } else {
                items[tableSelected] = {itemId: tableSelected, notes, people, quantity: tableQuantity};
            }
        }

        if (concierge) {
            items[concierge] = {itemId: concierge, notes: conciergeNotes, phone: itemPhone}
        }
        if (specialist) {
            items[specialist] = {itemId: specialist, phone: itemPhone}
        }
        if (driver) {
            items[driver] = {itemId: driver, driverType: driverType, phone: itemPhone}
        }
        if (security) {
            items[security] = {itemId: security, phone: itemPhone}
        }
        if (accessory) {
            items[accessory] = {itemId: accessory, accessoryType: "Azul", accessoryInitials: "Razvan"}
        }

        const itemsToPurchase = [];
        for (let key in items) {
            itemsToPurchase.push(items[key]);
        }
        console.log(items)
        setLoadingButton(true);
        const response = await fetch('https://partyapp-testing-443802.ew.r.appspot.com/api/cart/items', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({items: itemsToPurchase, eventId: eventId})
        })
        // const response = await fetch('/api/cart/items', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': `Bearer ${user.token}`
        //     },
        //     body: JSON.stringify({items: itemsToPurchase, eventId: eventId})
        // })
        if (response.ok) {
            navigate('/cart', { replace: true });
            setLoadingButton(false);
        } else {
            const json = await response.json();
            setLoadingButton(false);
            setErrorMessage(json.error);
            setShowError(true);
            setTimeout(() => {
                setShowError(false);
            }, 2500);
            setTimeout(() => {
                setErrorMessage(null);
            }, 2700);
        } 
    }

    const [errorMessage, setErrorMessage] = useState(null);
    const [showError, setShowError] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);

    const [loading, setLoading] = useState(false);

    const [eventName, setEventName] = useState("");
    const [date, setDate] = useState("");
    const [location, setLocation] = useState("");
    const [performers, setPerformers] = useState("");
    const [music, setMusic] = useState("");
    const [description, setDescription] = useState("");
    const [dressCode, setDressCode] = useState("");
    const [phone, setPhone] = useState("");
    const [tickets, setTickets] = useState([]);
    const [reservations, setReservations] = useState([]);
    const [extraServices, setExtraServices] = useState([]);
    const [hour, setHour] = useState();
    const [imageUrl, setImageUrl] = useState("");

    const fetchEvent = async() => {
        setLoading(true);
        const response = await fetch('https://partyapp-testing-443802.ew.r.appspot.com/api/event/', {
            headers: {'eventid': eventId}
        });
        // const response = await fetch('/api/event/', {
        //     headers: {'eventid': eventId}
        // });
        const json = await response.json();
        if (response.ok) {
            const information = json.information;
            setEventName(information.eventName);
            formatDate(information.date);
            setHour(information.hour);
            setLocation(information.location);
            setPerformers(information.performers);
            setMusic(information.music);
            setDescription(information.description);
            setDressCode(information.dressCode);
            setPhone(information.phone);
            setImageUrl(information.imageUrl)
            for (let item of information.items) {
                if (item.category == "ticket") {
                    setTickets(tickets => [...tickets, item]);
                } else if (item.category == "reservation") {
                    setReservations(reservations => [...reservations, item]);
                } else {
                    setExtraServices(extraServices => [...extraServices, item]);
                }
            }
        } else {
            console.log(json.erorr);
        }
        setLoading(false);
    }

    const formatDate = (rawDate) => {
        rawDate = new Date(rawDate);

        // Get the day of the week
        const options = { weekday: 'long', month: 'long', day: 'numeric' };
        const formattedDate = rawDate.toLocaleDateString('en-US', options);

        // Get the ordinal suffix for the day
        const day = rawDate.getDate();
        const suffix = (day) => {
            if (day > 3 && day < 21) return 'th'; // Catch 11th-19th
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };

        setDate(`${formattedDate.replace(/(\d+)/, `${day}${suffix(day)}`)}`);
    }

    useEffect(() => {
        const fetch = async() => {
            await fetchEvent();
        }
        fetch();
    }, [])




    return (
        <>
            <Navbar/>
            <CssBaseline/>
            {loading && 
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", height: "65vh"}}>
               <CircularProgress/>
            </Box>}
            {!loading && <>
            <Box
            sx={{
                position: "fixed",
                bottom: "90px",
                left: "5px",
                zIndex: "100",
                maxWidth: "90%"
            }}
            >
                <Slide direction="right" in={showSuccess && (successMessage != null)}>
                    <Alert>{successMessage}</Alert>
                </Slide>
            </Box>
            <Box
            sx={{
                position: "fixed",
                bottom: "95px",
                right: "15px",
                zIndex: "100",
                maxWidth: "90%"
            }}
            >
                <IconButton size="medium" variant="outlined" href="tel:+40744888111"
                sx={{
                    border: "solid",
                    backgroundColor: "white",
                    ":hover": {backgroundColor: "#9CA3AF"}}}
                >
                    <Phone/>
                </IconButton>
            </Box>
            <Box
            sx={{
                position: "fixed",
                bottom: "90px",
                left: "5px",
                zIndex: "100",
                maxWidth: "90%"
            }}
            >
                <Slide direction="right" in={showError && (errorMessage != null)}>
                    <Alert severity="error">{errorMessage}</Alert>
                </Slide>
            </Box>
            <Box
            sx={{
            }}>
                <Box
                sx={{
                    paddingTop: "50px",
                }}
                >
                    <Container maxWidth="lg" 
                    sx={{
                        display: "flex", flexDirection: "column", gap: "20px", minHeight: "100vh", paddingLeft: "32px", paddingRight: "32px"
                    }}>
                        <Box sx={{display: 'flex', gap: '30px', justifyContent: "center"}}>
                            <Box sx={{display: "flex", flexDirection: "column", width: '300px', gap: "20px"}}>
                                <Box component="img" src={imageUrl} maxWidth="md" sx={{height:"350px", borderRadius: "20px"}}/>
                            </Box>
                        </Box>
                        <Box
                        sx={{display: "flex", flexDirection: "column", gap: "4px", width: "100%", flexWrap: "wrap", alignItems: "center"}}
                        >
                            <Typography variant="h3" fontSize="15px">
                                {date} • {hour}
                            </Typography>
                            <Typography variant="h2" fontSize="42px">
                                {eventName}
                            </Typography>
                                <Box
                                sx={{display: "flex", gap: "15px", flexWrap: 'wrap', justifyContent: "center"}}
                                >   
                                    <Box
                                    sx={{display: "flex", gap: "4px", alignItems: "center"}}
                                    >   
                                        <Location sx={{color:"white", fontSize: "20px"}}/>
                                        <Typography variant="h3" fontSize="15px" color="white">
                                            {location}
                                        </Typography>
                                    </Box>
                                    <Box
                                    sx={{display: "flex", gap: "4px", alignItems: "center"}}
                                    >   
                                        <Headphones sx={{color:"#9CA3AF", fontSize: "20px"}}/>
                                        <Typography variant="h3" fontSize="15px" color="#9CA3AF">
                                            {performers}
                                        </Typography>
                                    </Box>
                                    {/* <Box
                                    sx={{display: "flex", gap: "4px", alignItems: "center"}}
                                    >   
                                        <Music sx={{color:"#9CA3AF", fontSize: "20px"}}/>
                                        <Typography variant="h3" fontSize="15px" color="#9CA3AF">
                                            {music}
                                        </Typography>
                                    </Box> */}
                                    <Box
                                    sx={{display: "flex", gap: "4px", alignItems: "center"}}
                                    >   
                                        <Phone sx={{color:"#9CA3AF", fontSize: "20px"}}/>
                                        <Typography variant="h3" fontSize="15px" color="#9CA3AF">
                                            {phone}
                                        </Typography>
                                    </Box>
                                </Box>
                        </Box>
                        <Divider sx={{bgcolor: "#1a1c1f", width: "100%"}}/>
                        <Box
                        sx={{display: "flex", flexDirection: "column", gap: "10px", width: "100%"}}>
                            <Typography variant="h2" fontSize="24px">
                                About
                            </Typography>
                            <Typography color="#9CA3AF" style={{whiteSpace: "pre-line"}}>
                             {description}
                            </Typography>
                            <Box sx={{display: "flex", gap: "5px", alignItems: "center"}}>
                                <Minor sx={{color:"white", fontSize: "20px"}}/>
                                <Typography color="white">
                                    Age restriction: 18+
                                </Typography>
                            </Box>
                            {/* <Box sx={{display: "flex", gap: "5px", alignItems: "center"}}>
                                <Clothing sx={{color:"white", fontSize: "20px"}}/>
                                <Typography color="white">
                                    Dress Code: {dressCode}
                                </Typography>
                            </Box> */}
                        </Box>
                        <Divider sx={{bgcolor: "#1a1c1f", width: "100%"}}/>
                        <Box
                            sx={{backgroundColor: 'transparent', display: "flex", flexDirection: "column", justifyContent: 'center', gap: "15px", width: '100%'}}
                            >   
                                <Typography color="white" fontWeight="600" fontSize="24px">Tickets</Typography>
                                {/* <Box
                                sx={{display: "flex", color: "white", justifyContent: "space-between", width: "100%", paddingLeft: '15px', paddingRight: '15px', paddingTop: '25px', paddingBottom: '25px', 
                                borderStyle: 'solid', borderColor: "#1a1c1f", borderRadius: '20px'}}
                                >
                                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", gap: "10px"}}>
                                        <Box
                                        sx={{display: "flex", flexDirection: "column", gap: "0px", color: "#9CA3AF"}}>
                                            <Typography fontSize="18px" fontWeight="600">
                                                General Access - 1st Release<br/>SOLD OUT
                                            </Typography>
                                            <Box
                                            sx={{display: "flex", gap: "5px"}}>
                                                <Typography fontSize="12px">
                                                    100 RON
                                                </Typography>
                                                <Typography fontSize="12px">
                                                •
                                                </Typography>
                                                <Typography fontSize="12px">
                                                    September 16 - 23
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                sx={{display: "flex", color: "white", justifyContent: "space-between", width: "100%", paddingLeft: '15px', paddingRight: '15px', paddingTop: '25px', paddingBottom: '25px', 
                                borderStyle: 'solid', borderColor: "#1a1c1f", borderRadius: '20px'}}
                                >
                                    <Box sx={{display: "flex", alignItems: "center", justifyContent: "center", gap: "10px"}}>
                                        <Box
                                        sx={{display: "flex", flexDirection: "column", gap: "0px", color: "#9CA3AF"}}>
                                            <Typography fontSize="18px" fontWeight="600">
                                                General Access - 2nd Release<br/>SOLD OUT
                                            </Typography>
                                            <Box
                                            sx={{display: "flex", gap: "5px"}}>
                                                <Typography fontSize="12px">
                                                    150 RON
                                                </Typography>
                                                <Typography fontSize="12px">
                                                •
                                                </Typography>
                                                <Typography fontSize="12px">
                                                    September 24 - October 1
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box> */}
                                {tickets.map((ticket) => (
                                    <TicketType key={ticket.itemId} ticketType={ticket} handleTicket={handleTicket}/>
                                ))}
                                {/* <Box
                                sx={{display: "flex", color: "white", justifyContent: "space-between", width: "100%", paddingLeft: '15px', paddingRight: '15px', paddingTop: '25px', paddingBottom: '25px', 
                                borderStyle: 'solid', borderColor: "#1a1c1f", borderRadius: '20px'}}
                                >
                                    <Typography><b fontSize="18px" fontWeight="600">VIP and Backstage tickets & tables</b><br/> Please contact us. </Typography>
                                </Box> */}
                        </Box>
                    </Container>
                </Box>
            </Box>
            <Slide direction="up" in={showCart}>
                <Box sx={{position: 'fixed', bottom: '0px',backdropFilter: "brightness(0%) blur(60px)", width: '100%', borderTop: 'solid', borderColor: "#1a1c1f", borderRadius: '20px'}}>
                    <Container sx={{ display: "flex", gap: '10px', justifyContent: 'space-between', width: '100%'
                        , padding: '20px'}}>
                        <Typography fontWeight="600" color="white" fontSize="20px">Total: {total + tablePrice * tableQuantity + extraServicesCost} RON</Typography>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center"
                            }}
                            >   
                                {loadingButton &&
                                    <CircularProgress size="1.5rem"/>
                                } 
                                <Button onClick={()=> completePurchase()} variant="contained" sx={{borderRadius:"10px", textTransform: "none", fontWeight: "600", backgroundColor: "white", color:"black", width: 'auto',
                                ":hover": {backgroundColor: "#BEC3CB", color: "black"}}}>
                                    <Typography fontWeight="600">Continue</Typography>
                                </Button>
                            </Box>
                    </Container>
                </Box>
            </Slide>
            </>}
            <Footer/>
        </>
    )
}